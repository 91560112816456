import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx'
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs/Subject'
import * as moment from 'moment';
import { Observable } from 'rxjs/Rx';

@Injectable()

export class DvcDataService {
  private ResortData: any[];
  public ResortSubject: Subject<Object[]> = new BehaviorSubject<Object[]>([]);
  public resorts$: Observable<Object[]> = this.ResortSubject.asObservable();  
  
  constructor(private db: AngularFirestore, private http: HttpClient) { 
    let resortStore = this.db.collection('resorts');
    resortStore.valueChanges().subscribe((data: any) => {
      this.ResortData = data;
      this.ResortSubject.next(this.ResortData);
    })
  }

  getResortData() {
    return this.ResortData;
  }

  getArrayOfDates(arrivalDate, departureDate) {
    let dateArray = new Array();
    let currentDate = moment(arrivalDate);
    arrivalDate = moment(arrivalDate)
    departureDate = moment(departureDate);
    while (currentDate < departureDate) {
      dateArray.push(currentDate.clone().format('MMDDYYYY').toString());
      currentDate.add(1, 'day');
    }
    return dateArray;
  }

  getPointsPerStay(dateArray: string[], resortCode) {
    // console.log(dateArray);
    let totalPoints: number = 0;
    let resortDocument: any = this.ResortData.filter(x => x.key == resortCode)
    dateArray.forEach(date => {
      let dailyPrice = resortDocument[0].Points[date]
      totalPoints = totalPoints + dailyPrice;
    })
    return totalPoints;
  }

  getRackRatePricing(dateArray: string[], resortCode) {
    let totalPrice: number = 0;
    let taxRate = 1.125;
    let resortDocument: any = this.ResortData.filter(x => x.key == resortCode)

    dateArray.forEach(date => {
      let dailyPrice = resortDocument[0].RackRates[date]
      totalPrice = totalPrice + dailyPrice;
    })
    return totalPrice * taxRate;
  }

  checkAvailability(dateArray: string[], resortCode) {
    let resortDocument: any = this.ResortData.filter(x => x.key == resortCode)
    if (resortDocument[0].Availability) {
      let daysAvailable = 0;
      dateArray.forEach(date => {
        if (resortDocument[0].Availability[date] == true) {
          daysAvailable = daysAvailable + 1;
        }
      })
      // console.log("days available", daysAvailable, "length", dateArray.length-1)
      if (daysAvailable == dateArray.length) {
        return {availability: "Full Availability", class: "success", value: 3}
      } else if (daysAvailable > 0 && daysAvailable <= dateArray.length) {
        return {availability: "Partial Availability", class: "warning", value: 2}
      } else {
        return {availability: "No Availability", class: "danger", value: 1}
      }
    } else return {availability: "Unknown Availability", class: "secondary", value: 0}
  }
}
