// import { totalmem } from 'os';
import { setTime } from 'ngx-bootstrap/timepicker/timepicker.utils';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, PACKAGE_ROOT_URL, setTestabilityGetter, ViewChild } from '@angular/core';
import { DvcDataService } from '../dvc-data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';

@Component({
  selector: 'app-optimizer',
  templateUrl: './optimizer.component.html',
  styleUrls: ['./optimizer.component.css']
})

export class OptimizerComponent implements OnInit {
  @ViewChild('checkAvailableOnly') private checkAvailable;
  
  ResortData: any[] = [];
  ResortStore;
  Resorts = [];
  RoomTypes = [];
  ViewTypes = [];
  SelectedResort;
  SelectedRoomType;
  SelectedView;
  StartDate;
  EndDate;
  bsConfigObject = {showWeekNumbers: false}
  MinPoints: number = 0;
  MaxPoints: number = 0;
  MinNights: number = 0;
  MaxNights: number = 0;
  SelectedNumberOfResults ="10";
  ResultArray = [];
  NumCombinations = 0;
  DisplayResults: boolean = false;
  
  constructor(private db: AngularFirestore, private http: HttpClient, private dvcData: DvcDataService) {
  }

  ngOnInit() {
    this.dvcData.resorts$.subscribe(resorts => {
      this.ResortData = resorts;
      // console.log(this.ResortData)
      resorts.forEach((resort: any) => {
        this.Resorts.push(resort.ResortName);
      })
       this.Resorts = Array.from(new Set(this.Resorts))
    })
  }

  foo(date) {
    console.log(date.bsValue)
  }
  updatePricePerPointsAtAllResorts() {
    this.ResortData.forEach(resort => {
      let currentDate = new Date('01-01-2018');
      let currentResortPPPObject = {};
      for(let x = 1; x <= 365; x++) {
        let currentDateString = `${("0" + (currentDate.getMonth() + 1)).slice(-2)}${("0" + currentDate.getDate()).slice(-2)}${currentDate.getFullYear()}`
        let rackrate = resort.RackRates[currentDateString]*1.125;
        let points = resort.Points[currentDateString];
        let pricePerPoint = rackrate / points;
        new Date(currentDate.setDate(currentDate.getDate() + 1));
        currentResortPPPObject[currentDateString] = pricePerPoint
      }; 
      this.db.collection("resorts").doc(resort.key).update({
          PricePerPoints: currentResortPPPObject
        })

    }) 
  }

  calculateResults(el) {
    this.ResultArray = [];
    this.DisplayResults = false;
    if(this.StartDate && this.EndDate) {
      let resultArray = [];
      //get min & max nights
      let maxNightStay = Number(this.MaxNights);
      let minNightStay = Number(this.MinNights);
      let minPoints = Number(this.MinPoints);
      let maxPoints = Number(this.MaxPoints);

      if(Number(this.MaxNights) <= 0) {
        maxNightStay = 14;
      }
      if(Number(this.MinNights) <= 0) {
        minNightStay = 1;
      }

      if(Number(this.MaxPoints) <= 0) {
        maxPoints = 5000;
      }

      let startDate = moment(this.StartDate)
      let endDate = moment(this.EndDate)
      let daysToCheck = endDate.diff(startDate, 'days');
      let filteredResortArray = this.ResortData.slice();
      if(this.SelectedResort) {
        filteredResortArray = filteredResortArray.filter(resort => resort.ResortName == this.SelectedResort);
        if(this.SelectedRoomType) {
          filteredResortArray = filteredResortArray.filter(resort => resort.RoomType == this.SelectedRoomType);
          if(this.SelectedView) {
            filteredResortArray = filteredResortArray.filter(resort => resort.ViewType == this.SelectedView);
          }
        }
      }
 
      filteredResortArray.forEach(resort => {
        for (let dateOffset = 0; dateOffset < daysToCheck; dateOffset ++) {
          let anchoredArrivalDate = startDate.clone().add(dateOffset,'day');
          let anchoredArrivalDateString = anchoredArrivalDate.format('MMDDYYYY');
          //let currentDepartureDate = startDate.clone().add(minNightStay,'day');
          for(let nights = minNightStay; nights <= maxNightStay; nights++) {
            let currentDepartureDate = moment(anchoredArrivalDate).clone().add(nights, 'day');
            let dateArray = this.dvcData.getArrayOfDates(anchoredArrivalDate, currentDepartureDate);
            let points = this.dvcData.getPointsPerStay(dateArray, resort.key);
            let availability = this.dvcData.checkAvailability(dateArray, resort.key);

            if(!points) {
              points = 0;
            }
            
            let resultObject = {
              ResortName: resort.ResortName,
              RoomType: resort.RoomType,
              ViewType: resort.ViewType,
              CheckIn: anchoredArrivalDate.clone().format('MM/DD/YYYY').toString(),
              CheckOut: currentDepartureDate.clone().format('MM/DD/YYYY').toString(),
              Points: points,
              Availability: availability,
            }
            resultArray.push(resultObject);
            
          }
        }
      })
      console.log(resultArray)
      if(this.checkAvailable.nativeElement.checked == true) {
        resultArray = resultArray.filter(x => x.Availability.availability == "Full Availability")
      }

      resultArray.sort(function(a,b) {return (a.Availability.value < b.Availability.value) ? 1 : ((b.Availability.value < a.Availability.value) ? -1 : 0);} )
      setTimeout(() => {
        resultArray = resultArray.filter(y => y.Points < maxPoints && y.Points > minPoints)
        this.NumCombinations = resultArray.length;
        this.ResultArray = resultArray.slice(0, Number(this.SelectedNumberOfResults));
        this.DisplayResults = true;
        setTimeout(()=>{
          el.scrollIntoView()
        },200)
      },500)
      
    }
  }
  

  resetInputToBlank(element) {
    if(element.value == 0 || element.value == "0") {
      element.value = "";
    }
  }

  resetInputToZero(element) {
    if(!element.value || element.value == "") {
      element.value = 0;
    }
  }

  onResortSelect() {
    let roomTypes = this.ResortData.filter(resort => resort.ResortName == this.SelectedResort).map(resort2 => resort2.RoomType);
    this.RoomTypes = Array.from(new Set(roomTypes))
  }

  onRoomTypeSelect() {
    let viewTypes = this.ResortData.filter(roomType => roomType.ResortName == this.SelectedResort && roomType.RoomType == this.SelectedRoomType)
      .map(roomType2 => roomType2.ViewType);
    this.ViewTypes = Array.from(new Set(viewTypes))
  }

  onArrivalDateSelect(d2) {
    if(this.StartDate) {
      this.EndDate = this.StartDate;
      setTimeout(() => {
        d2.toggle()
      },100)
    }
  
    //Change DepartureDate Default to +1
    //Open DepartureBox
  }

  getArrayOfDates(arrivalDate: Date, departureDate: Date) {
    let dateArray = new Array();
    let currentDate: Date = arrivalDate;
    while (currentDate <= departureDate) {
      dateArray.push(this.convertDateToCode(currentDate));
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    //console.log(dateArray);
    return dateArray;
  }

  convertDateToCode(date: Date) {
    let dateCodeFormat = `${("0" + (date.getMonth() + 1)).slice(-2)}${("0" + date.getDate()).slice(-2)}${date.getFullYear()}`;
    return dateCodeFormat;
  }    

  getRackRatePricing(dateArray: string[]) {
    let totalPrice: number = 0;
    let resortDocument: any = this.ResortData.filter(x => {
      if(x.ResortName == this.SelectedResort && x.RoomType == this.SelectedRoomType && x.ViewType == this.SelectedView) {
        return true;
      } 
    })

    dateArray.forEach(date => {
      let dailyPrice = resortDocument[0].RackRates[date]
      totalPrice = totalPrice + dailyPrice;
    })
    return totalPrice;
  }

  

  getPointsPerStay(dateArray: string[]) {
    let totalPoints: number = 0;
    let resortDocument: any = this.ResortData.filter(x => {
      if(x.ResortName == this.SelectedResort && x.RoomType == this.SelectedRoomType && x.ViewType == this.SelectedView) {
        return true;
      } 
    })

    dateArray.forEach(date => {
      let dailyPrice = resortDocument[0].Points[date]
      totalPoints = totalPoints + dailyPrice;
    })
    return totalPoints;
  }
}