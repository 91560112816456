import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { PopoverModule, PopoverDirective } from 'ngx-bootstrap/popover';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ViewChild } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { listLocales } from 'ngx-bootstrap/bs-moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Options } from 'fullcalendar';

@Component({
  selector: 'app-points-calculator',
  templateUrl: './rooms-available.component.html',
  styleUrls: ['./rooms-available.component.css']
})
export class RoomsAvailableComponent implements OnInit {
  ResortData: any[] = [];
  bsConfigObject = {showWeekNumbers: false};
  ResortStore;
  popoverContent = "";
  resortObject = {};
  ArrivalDate: Date;
  DepartureDate: Date;
  closeResult: string;
  DateArray: string[] = [];
  private _myModal;
  hoverID = {};
  calendarOptions;
  detailsModalData: any = {};
  detailsModalItem;
  comparisonModalItem;
  ComparisonArray = [];
  calendarPlugins = [dayGridPlugin]; // important!
  @ViewChild('content') private content;
  @ViewChild('requestModal') private requestModal;
  @ViewChild('comparisonModal') comparisonModal;
  @ViewChild('arrivalDate') private date1;
  @ViewChild('departureDate') private date2;

  constructor(private http: HttpClient, private modalService: BsModalService, private db: AngularFireDatabase) { }

  ngOnInit() {
    let today = moment();
    let elevenMonthsFromNow = moment().add(11, 'months');
    this.date1.minDate = today.toDate();
    this.date1.maxDate = elevenMonthsFromNow.toDate();
    this.date1.showWeekNumbers = false;
  }

  addToComparison() {
    if(this.ComparisonArray.length < 4) {
      this.detailsModalData.id = this.ComparisonArray.length + 1;
      this.ComparisonArray.push(this.detailsModalData)
      this.detailsModalItem.hide();
    }
  }

  closeComparisonModal() {
    this.comparisonModalItem.hide()
  }

  showComparisonModal() {
    this.comparisonModalItem = this.modalService.show(this.comparisonModal, {class: "modal-lg"});
  }

  removeFromComparison(id) {
    this.ComparisonArray.splice(id - 1, 1);
    this.ComparisonArray.forEach((res, index) => {
      res.id = index + 1;
    })
    if(this.ComparisonArray.length < 1) {
      this.comparisonModalItem.hide();
    }
  }

  async showDetailsModal(resortCode: string) {
    let apiInfo;
    let url = `https://dvc-points.herokuapp.com/get-resort-availability?resort=${resortCode}`
    if(this.resortObject[resortCode] && this.resortObject[resortCode].output) {
      await this.http.get(url).toPromise().then(response => {
        apiInfo = response;
      })
      this.detailsModalData = {
        availabilityDates: [],
        isAvailable: this.resortObject[resortCode].Availability.availability,
        arrivalDate: moment(this.ArrivalDate).format("M/D/YYYY").toString(),
        departureDate: moment(this.DepartureDate).format("M/D/YYYY").toString(),
        resort: this.resortObject[resortCode].ResortName,
        roomType: this.resortObject[resortCode].RoomType,
        viewType: this.resortObject[resortCode].ViewType,
        points: this.resortObject[resortCode].Points,
        occupancy: this.resortObject[resortCode].Occupancy,
        floorPlanImg: this.resortObject[resortCode].FloorPlanImg
      }
      let selectedResort: any = this.resortObject[resortCode]
      let availableDates = selectedResort;
      Object.keys(apiInfo.Availability).forEach((date: any) => {
        let title = (Number(apiInfo.Element[date]) - 300)/5-4;
        let startDate = moment(date, "MMDDYYYY").format("YYYY-MM-DD").toString();
        let color = "red";
        if((apiInfo.Element[date] - 300)/5-4 > 0) {
          color = "green";
        }
        let object = {title: title, start: startDate, textColor: "white", backgroundColor: color}
        this.detailsModalData.availabilityDates.push(object);
      })

      this.calendarOptions = {

        editable: false,
        defaultDate: moment(this.ArrivalDate).format('YYYY-MM-DD').toString(),
        eventLimit: false,
        header: {
          left: 'prev,next',
          center: 'title',
          right: '',
        },
        events: this.detailsModalData.availabilityDates
      };

      this.detailsModalItem = this.modalService.show(this.requestModal);
    }
  }

  closeDetailsModal() {
    this.detailsModalItem.hide();
  }

  convertObjectToArray(objectToConvert) {
    let newArray = [];
    Object.keys(objectToConvert).forEach(key => {
      let objectToEnter = {
        
      }
    })
  }

  getResortData() {
    if(this.ArrivalDate && this.DepartureDate) {
      let arrivalDate = this.ArrivalDate;
      let departureDate = this.DepartureDate;
      let arrivalDateString = moment(arrivalDate).clone().format('YYYYMMDD').toString();
      let departureDateString = moment(departureDate).clone().format('YYYYMMDD').toString();
      let url = `https://dvc-points.herokuapp.com/get-resort-info?arrivalDate=${arrivalDateString}&departureDate=${departureDateString}`;
      this.http.get(url).subscribe(data => {
        this.resortObject = data;
        this.showPoints();
        this.showAvailability();
      })
      // this.ResortData.forEach(resort => {
      //   let resortData = resort;
      //   let points = this.getPoints(arrayOfDates, resortData);
      //   let availability = this.getAvailability(arrayOfDates, resortData);
      //   let price = this.getPricePerPoint(resort.key, points, arrivalDate) * points;
      //   let rackRate = this.getRackRate(arrayOfDates, resortData);
      //   let savingsPercent = Math.round((rackRate - price) / rackRate * 100);
      //   let savingsDollars = Math.round(rackRate - price);
      //   let resultObject = {
      //     occupancy: resortData.Occupancy,
      //     points: points,
      //     availability: availability.availability,
      //     availabilityArray: availability.availabilityArray,
      //     resort: resortData.ResortName,
      //     roomType: resortData.RoomType,
      //     viewType: resortData.ViewType,
      //     floorPlanImg: resortData.FloorPlanImg
      //   }
      //   this.resortObject[resort.key] = resultObject;
      // })
      
    }
  }
  
  showPoints() {
    Object.keys(this.resortObject).forEach((resort: any) => {
      this.resortObject[resort].output = this.resortObject[resort].Points;
    })
  }

  // async getDetailsInfo(resortCode) {

  // }
  
  // showPrice() {
  //   Object.keys(this.resortObject).forEach((resort: any) => {
  //     this.resortObject[resort].output = `$${this.resortObject[resort].rentalPrice}`;
  //   })
  // }
  
  // showSavingsDollars() {
  //   Object.keys(this.resortObject).forEach((resort: any) => {
  //     let x;
  //     if (this.resortObject[resort].savingsDollars <= 0) {
  //       x = "N/A";
  //     } else {
  //       x = `$${this.resortObject[resort].savingsDollars}`;
  //     }
  //     this.resortObject[resort].output = x;
  //   })
  // }
  
  // showSavingsPercent() {
  //   Object.keys(this.resortObject).forEach((resort: any) => {
  //     let x;
  //     if (this.resortObject[resort].savingsPercent <= 0) {
  //       x = "N/A";
  //     } else {
  //       x = `${this.resortObject[resort].savingsPercent}%`;
  //     }
  //     this.resortObject[resort].output = x;
  //   })
  // }
  
  showAvailability() {
    Object.keys(this.resortObject).forEach((resort: any) => {
      if (this.resortObject[resort].Availability.availability == "Full") {
        this.resortObject[resort].color = "#c8e2ba";
      } else if (this.resortObject[resort].Availability.availability == "Partial") {
        this.resortObject[resort].color = "#ffdb63";
      } else if (this.resortObject[resort].Availability.availability == "None") {
        this.resortObject[resort].color = "#f0b0b7";
      } else {
        this.resortObject[resort].color = "grey";
      }
    })
  }
  
  onArrivalDateSelect(event) {
    this.ArrivalDate = event;
    this.DepartureDate = moment(event).add(1, 'd').toDate();
    this.date2.minDate =  moment(this.ArrivalDate).add(1,'d').toDate();
    let maxArrivalDate14 = moment(this.ArrivalDate).add(14, 'days')
    let maxArrivalDate7 = moment().add(11, 'months').add(7, 'days');
    let maxArrivalDate;
    if(maxArrivalDate14 < maxArrivalDate7) {
      maxArrivalDate = maxArrivalDate14;
      
    } else {
      maxArrivalDate = maxArrivalDate7.clone();
    }
    
    this.date2.maxDate = maxArrivalDate.toDate();

    if(this.ArrivalDate) {
      this.DepartureDate = moment(this.ArrivalDate).add(1, 'd').toDate();
      setTimeout(() => {
        this.date2.show()
      },100)
    }
  }

  // getArrayOfDates(arrivalDate, departureDate) {
  //   let dateArray = new Array();
  //   let currentDate = moment(arrivalDate);
  //   let startDate = moment(arrivalDate)
  //   let endDate = moment(departureDate).subtract(1, 'days');
  //   while (currentDate <= endDate) {
  //   dateArray.push(currentDate.clone().format('MMDDYYYY').toString());
  //   currentDate.add(1, 'day');
  //   }
  //   return dateArray;
  // }
 
//  getPoints(dateArray, resortData) {
//     let totalPoints = 0;
//     dateArray.forEach(date => {
//     let dailyPrice = resortData.Points[date]
//     totalPoints = totalPoints + dailyPrice;
//     })
//     return totalPoints;
//   }
 
//  getAvailability(dateArray, resortData) {
//     let availabilityArray = [];
//     if (resortData.Availability) {
//        let daysAvailable = 0;

//        dateArray.forEach(date => {
//          availabilityArray.push({date: date, isAvailable: resortData.Availability[date]})
//           if (resortData.Availability[date] == true) {
//              daysAvailable = daysAvailable + 1;
//           }
//        })
      
//        if (daysAvailable == dateArray.length) {
//           return {availability: "Full", class: "success", availabilityArray: availabilityArray}
//        } else if (daysAvailable > 0 && daysAvailable < dateArray.length) {
//           return {availability: "Partial", class: "warning", availabilityArray: availabilityArray}
//        } else {
//           return {availability: "None", class: "danger", availabilityArray: availabilityArray}
//        }
//     } else return {availability: "Unknown", class: "secondary", availabilityArray: availabilityArray}
//  }
 
//  getPricePerPoint(resortCode, numberOfPoints, arrivalDate) {
//      var monthsOut = moment(arrivalDate).diff(moment(), 'months', true);
//      var pricePerPoint=1;
//      var resortSpecArray = resortCode.split('-');
//      var resortNumber = resortSpecArray[0];
//      var isPremium;
//      if (resortNumber == "POL" || resortNumber == "SS") {
//        isPremium = false;
//      } else {
//        isPremium = true;
//      }
 
//      if ((monthsOut <= 7 && numberOfPoints >= 50)  || (monthsOut > 7 && numberOfPoints >= 50 && isPremium == false)) {
//        pricePerPoint = 17;
//      } else if ((monthsOut <= 7 && numberOfPoints < 50)  || (monthsOut > 7 && numberOfPoints < 50 && isPremium == false) || (monthsOut > 7 && numberOfPoints >= 50 && isPremium == true)) {
//        pricePerPoint = 18;
//      } else if (monthsOut > 7 && numberOfPoints < 50 && isPremium == true) {
//        pricePerPoint = 19;
//      }
//      return pricePerPoint;
//    }
   
//    getRackRate(dateArray, resortData) {
//      let totalPrice = 0;
//      let taxRate = 1.125;
     
//      dateArray.forEach(date => {
//        if (resortData.RackRates) {
//        let dailyPrice = resortData.RackRates[date]
//        totalPrice = totalPrice + dailyPrice;
//        }
//      })
//      return totalPrice * taxRate;
//    }
}
