import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireDatabase } from '@angular/fire/database';
import dayGridPlugin from '@fullcalendar/daygrid';
import { BsModalService } from 'ngx-bootstrap/modal'
import * as moment from 'moment';
import { ViewChild } from '@angular/core';

@Component({
  selector: 'app-points-calculator',
  templateUrl: './points-calculator.component.html',
  styleUrls: ['./points-calculator.component.css']
})
export class PointsCalculatorComponent implements OnInit {
  ResortData: any[] = [];
  confirmedReservations: any[] = [];
  bsConfigObject = {showWeekNumbers: false};
  ResortStore;
  popoverContent = "";
  resortObject = {};
  ArrivalDate: Date;
  DepartureDate: Date;
  closeResult: string;
  DateArray: string[] = [];
  hoverID = {};
  calendarOptions;
  detailsModalData: any = {};
  detailsModalItem;
  comparisonModalItem;
  ComparisonArray = [];
  calendarPlugins = [dayGridPlugin]; // important!
  @ViewChild('content') private content;
  @ViewChild('requestModal') private requestModal;
  @ViewChild('comparisonModal') comparisonModal;
  @ViewChild('arrivalDate') private date1;
  @ViewChild('departureDate') private date2;

  constructor(private http: HttpClient, private modalService: BsModalService, private db: AngularFireDatabase) { }

  ngOnInit() {
    let today = moment();
    let elevenMonthsFromNow = moment().add(11, 'months');
    this.date1.minDate = today.toDate();
    this.date1.maxDate = elevenMonthsFromNow.toDate();
    this.date1.showWeekNumbers = false;
  }

  addToComparison() {
    if(this.ComparisonArray.length < 4) {
      this.detailsModalData.id = this.ComparisonArray.length + 1;
      this.ComparisonArray.push(this.detailsModalData)
      this.detailsModalItem.hide();
    }
  }

  onSearchClicked() {
    this.getResortData();
    this.getConfirmedReservations();
  }

	goToDVCRS(resort: string, roomType:string, viewType: string, arrivalDate: string, departureDate: string) {
		let parameterObject = {
			field_247: arrivalDate,
			field_248: departureDate,
			field_249: resort,
			field_250: roomType,
			field_251: viewType
		}
		console.log(JSON.stringify(parameterObject))
		let urlEncodedParameters = encodeURI(JSON.stringify(parameterObject))
		let parameterString = `?view_140_vars=${urlEncodedParameters}`
    console.log(parameterString)
    window.open('https://dvcrentalstore.com/request-free-obligation-quote-step-2/', "_blank")
	}

  closeComparisonModal() {
    this.comparisonModalItem.hide()
  }

  showComparisonModal() {
    this.comparisonModalItem = this.modalService.show(this.comparisonModal, {class: "modal-lg"});
  }

  bookReservationClick(id) {
    window.open(`https://dvcrentalstore.com/guests/reservations/${id}/`, "_blank")
  }

  removeFromComparison(id) {
    this.ComparisonArray.splice(id - 1, 1);
    this.ComparisonArray.forEach((res, index) => {
      res.id = index + 1;
    })
    if(this.ComparisonArray.length < 1) {
      this.comparisonModalItem.hide();
    }
  }

  async showDetailsModal(resortCode: string) {
    let apiInfo;
    let url = `https://dvc-points.herokuapp.com/get-resort-availability?resort=${resortCode}`
    if(this.resortObject[resortCode] && this.resortObject[resortCode].output) {
      await this.http.get(url).toPromise().then(response => {
        apiInfo = response;
      })
      this.detailsModalData = {
        availabilityDates: [],
        isAvailable: this.resortObject[resortCode].Availability.availability,
        arrivalDate: moment(this.ArrivalDate).format("M/D/YYYY").toString(),
        departureDate: moment(this.DepartureDate).format("M/D/YYYY").toString(),
        resort: this.resortObject[resortCode].ResortName,
        roomType: this.resortObject[resortCode].RoomType,
        viewType: this.resortObject[resortCode].ViewType,
        points: this.resortObject[resortCode].Points,
        occupancy: this.resortObject[resortCode].Occupancy,
        floorPlanImg: this.resortObject[resortCode].FloorPlanImg
      }
      let selectedResort: any = this.resortObject[resortCode]
      let availableDates = selectedResort;
      Object.keys(apiInfo.Availability).forEach((date: any) => {
        let title = apiInfo.Points[date];
        let startDate = moment(date, "MMDDYYYY").format("YYYY-MM-DD").toString();
        let color = "red";
        if(apiInfo.Availability[date] == true) {
          color = "green";
        }
        let object = {title: title, start: startDate, textColor: "white", backgroundColor: color}
        this.detailsModalData.availabilityDates.push(object);
      })

      this.calendarOptions = {

      editable: false,
      defaultDate: moment(this.ArrivalDate).format('YYYY-MM-DD').toString(),
		  eventLimit: false,
		  contentHeight: "1000px",
        header: {
          left: 'prev,next',
          center: 'title',
          right: '',
        },
        events: this.detailsModalData.availabilityDates
      };

      this.detailsModalItem = this.modalService.show(this.requestModal);
    }
  }

  closeDetailsModal() {
    this.detailsModalItem.hide();
  }

  convertObjectToArray(objectToConvert) {
    let newArray = [];
    Object.keys(objectToConvert).forEach(key => {
      let objectToEnter = {

      }
    })
  }

  getResortData() {
    if(this.ArrivalDate && this.DepartureDate) {
      let arrivalDate = this.ArrivalDate;
      let departureDate = this.DepartureDate;
      let arrivalDateString = moment(arrivalDate).clone().format('YYYYMMDD').toString();
      let departureDateString = moment(departureDate).clone().format('YYYYMMDD').toString();
      let url = `https://dvc-points.herokuapp.com/get-resort-info?arrivalDate=${arrivalDateString}&departureDate=${departureDateString}`;
      this.http.get(url).subscribe(data => {
        this.resortObject = data;
        this.showPoints();
        this.showAvailability();
      })
    }
  }

  getConfirmedReservations() {
    if(this.ArrivalDate && this.DepartureDate) {

      let arrivalDate = moment(this.ArrivalDate).clone().startOf('day').format('YYYY-MM-DD');
      let departureDate = moment(this.DepartureDate).clone().startOf('day').format('YYYY-MM-DD');
      debugger;
      let url = `https://dvcrs.herokuapp.com/matching-reservations?checkIn=${arrivalDate}&checkOut=${departureDate}`;
      this.http.get(url).subscribe((data: any) => {
        data.map(res => (
          [
            {
              ...res,
              checkIn: new Date(res.checkInString),
              checkOut: new Date(res.checkOutString)
            }
          ]
        ))
        this.confirmedReservations = data;
      })
    }
  }

  showPoints() {
    Object.keys(this.resortObject).forEach((resort: any) => {
      this.resortObject[resort].output = this.resortObject[resort].Points;
    })
  }

  showAvailability() {
    Object.keys(this.resortObject).forEach((resort: any) => {
      if (this.resortObject[resort].Availability.availability == "Full") {
        this.resortObject[resort].color = "#c8e2ba";
      } else if (this.resortObject[resort].Availability.availability == "Partial") {
        this.resortObject[resort].color = "#ffdb63";
      } else if (this.resortObject[resort].Availability.availability == "None") {
        this.resortObject[resort].color = "#f0b0b7";
      } else {
        this.resortObject[resort].color = "grey";
      }
    })
  }

  onArrivalDateSelect(event) {
    this.ArrivalDate = event;
    this.DepartureDate = moment(event).add(1, 'd').toDate();
    this.date2.minDate =  moment(this.ArrivalDate).add(1,'d').toDate();
    let maxArrivalDate14 = moment(this.ArrivalDate).add(14, 'days')
    let maxArrivalDate7 = moment().add(11, 'months').add(7, 'days');
    let maxArrivalDate;
    if(maxArrivalDate14 < maxArrivalDate7) {
      maxArrivalDate = maxArrivalDate14;

    } else {
      maxArrivalDate = maxArrivalDate7.clone();
    }

    this.date2.maxDate = maxArrivalDate.toDate();

    if(this.ArrivalDate) {
      this.DepartureDate = moment(this.ArrivalDate).add(1, 'd').toDate();
      setTimeout(() => {
        this.date2.show()
      },100)
    }
  }
}
