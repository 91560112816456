import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule }   from '@angular/router';
import { HttpClientModule} from '@angular/common/http';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { FullCalendarModule } from 'ng-fullcalendar';
import { environment } from '../environments/environment';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular'
import { AppComponent } from './app.component';
import { PointsCalculatorComponent } from './points-calculator/points-calculator.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth'; 
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { OptimizerComponent } from './optimizer/optimizer.component';
import { DvcDataService} from './dvc-data.service';
import { RoomsAvailableComponent } from './rooms-available/rooms-available.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { ReferCalculatorComponent } from './refer-calculator/refer-calculator.component';

@NgModule({
  declarations: [
    AppComponent,
    PointsCalculatorComponent,
    OptimizerComponent,
    RoomsAvailableComponent,
    LoginComponent,
    ReferCalculatorComponent
  ],
  imports: [
    // AlertModule,
    // NgbModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
	 AngularFireModule.initializeApp(environment.firebase),
	 AngularFireAuthModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireDatabaseModule,
    FormsModule,
    FullCalendarModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: 'points-calculator',
        component: PointsCalculatorComponent
      },
      {
        path: 'optimizer',
        component: OptimizerComponent
      },
      {
        path: 'rooms',
		  component: RoomsAvailableComponent,
		  canActivate: [AuthGuard]
		},
		{
			path: 'points-calculator-refer',
			component: ReferCalculatorComponent
		},
		{
			path: 'login',
			component: LoginComponent
		}
    ])
  ],
  providers: [DvcDataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
