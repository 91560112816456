import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	Username: string;
	Password: string;
	isLoggedIn: boolean;
	
	constructor(private auth: AngularFireAuth, private router: Router) { }

	ngOnInit() {
		this.auth.authState.subscribe((user: any) => {
			if (user & user.uid) {
				this.isLoggedIn=true;
			} else {this.isLoggedIn = false;}
		})
	}

	login() {
		this.auth.auth.signInWithEmailAndPassword(this.Username, this.Password)
		 .then(data => this.router.navigate(['/rooms']))
		  .catch(err => alert("Wrong email or password.  Please try again"))
	 }

	 logout() {
		 this.auth.auth.signOut()
	 }

}
